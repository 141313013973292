import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {/* <span className="purple">THIS IS FIRE : </span> */}
            <br />
            $CODE on the Solana blockchain, powered by the robust Rust programming language, signifies a groundbreaking step in decentralized finance. This innovative venture incorporates liquidity pool (LP) burn mechanisms, enhancing token scarcity and value. With notable figures like Raj Gokal and Anatoly, founders of Solana, expressing support on Twitter, $CODE is poised to redefine the Solana ecosystem. The integration of Rust, known for its efficiency and performance, ensures a secure and seamless experience, solidifying $CODE's position as a pioneering force in the evolving landscape of blockchain and cryptocurrency.            <br />
          </p>
          {/* <ul>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              ROAD MAP <strong className="purple"> </strong>
            </h1>
            <li className="about-activity">
              <ImPointRight /> 🔥 BUY
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 HOLD
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 STAY ON FIRE
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "JOIN THE FUTURE OF AI"{" "}
          </p> */}
          <footer className="blockquote-footer"> CODE </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;


