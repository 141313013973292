import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import r1 from "../../Assets/logo.png";
import r2 from "../../Assets/logo.png";
import { pankcake } from "../../links";
import { ImPointRight } from "react-icons/im";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
            <Col>
              <img src={r1} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $CODE NOW
                </a>
              </button>
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="project-heading">
                BUY  <strong className="purple">  $CODE</strong> NOW
              </h1>
              <p style={{ color: "white" }}>
                HOW TO BUY ?
              </p>

              <h1 className="project-heading2">
                1. Create <strong className="purple">SOLANA  WALLET</strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />Create a Phantom or Solflare Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive $CODE.
                <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                2. Send <strong className="purple"> SOL </strong>to your wallet
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />You can buy SOLANA (SOL) and transfer it from exchanges like Coinbase, Binance, etc.

                <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                3.  <strong className="purple">Connect & Swap </strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight /> Go to Jupiter (jup.ag) and connect your wallet by clicking ‘Connect wallet’ and selecting MetaMask. Press ‘Select a token’ and enter the token contract address to find $CODE and start your swap from SOL to $CODE..<strong className="purple"> </strong>
              </h1>
            </Col>
            <Col>
              <img src={r2} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $CODE NOW
                </a>
              </button>
            </Col>



          </Row>
          {/* <h1 className="project-heading">
            All <strong className="purple"> Listing</strong>
          </h1> */}

          {/* <Techstack /> */}

          {/* <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
        </Container>
      </Container>
      <Container fluid className="about-section">
        <h1 className="project-heading">
          <strong className="purple">  $CODE CHART</strong>
        </h1>
        <iframe src="https://dexscreener.com/solana/BbvSynTymhn3AxeDgpXkbb8mNvrUaAG3XZ4KGyyeh4NL?embed=1&amp;theme=dark&amp;trades=0&amp;info=0" className="charts"></iframe>
      </Container>
    </div>
  );
}

export default ResumeNew;
