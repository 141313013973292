import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import logo from "../../Assets/logo.png";
import { pankcake } from "../../links";


function About() {
  return (
    <section id="about">
      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
            <Col>
              <img src={logo} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $CODE NOW
                </a>
              </button>
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                Know MORE <strong className="purple"> </strong>
              </h1>
              <Aboutcard />
            </Col>



          </Row>
          {/* <h1 className="project-heading">
            All <strong className="purple"> Listing</strong>
          </h1> */}

          {/* <Techstack /> */}

          {/* <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
        </Container>
      </Container>
    </section>
  );
}

export default About;
