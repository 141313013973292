export const telegram = "https://t.me/codesolana"
export const twitter = "https://twitter.com/codesolana"
export const poo = "https://birdeye.so/token/BbvSynTymhn3AxeDgpXkbb8mNvrUaAG3XZ4KGyyeh4NL"
export const pankcake = "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=BbvSynTymhn3AxeDgpXkbb8mNvrUaAG3XZ4KGyyeh4NL"


// echo "# pepetok" >> README.md
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/m0nithin/pepetok.git
// git push -u origin main